<template>
  <b-container class="min-vh-100 d-flex flex-column">
    <b-row>
      <b-col cols="1"/>
      <b-col>
        <h1 style="margin-top: 4.25rem;">Bikeshare<br>HfG Gmünd</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="1"/>
      <b-col cols="10">
        <img src="@/assets/illustrations/bikerwhiteshirt.svg" alt="biker" style="width: 100%; height: 100%; margin-top: 1.5rem; margin-bottom: 1.25rem;"/>
      </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row>
      <b-col cols="1"/>
      <b-col cols="10">
        <h4 style="margin-top: 2rem;">Willkommen bei bikeshare der HfG Gmünd!</h4>
        <h4 style="">Benutze diese App, um ein Fahrrad auszuleihen oder für andere HfGler anzubieten.</h4>
      </b-col>
    </b-row>
    <b-row class="flex-grow-1">
      <b-col cols="1"/>
      <b-col class="justify-content-center align-self-center">
        <b-button variant="primary" style="width: 100%;" @click="$router.push('/tos')" text="Login um zu starten">Login um zu starten</b-button>
      </b-col>
      <b-col cols="1"/>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'Start',
  components: {
  }
}
</script>

<style>
</style>